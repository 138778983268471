<template>
  <div>
    <div class="path"
         v-show="show">
      <div class="study">
        <div class="path_title">
          <img src="../../images/member_new/path_study@2x.png">
          <span>学习时长统计</span>
        </div>
        <ul class="time_content">
          <li>
            <p>{{$pub.formatSeconds(path.study.today_time)}}</p>
            <span>昨日学习</span>
          </li>
          <li>
            <p>{{$pub.formatSeconds(path.study.week_time)}}</p>
            <span>本周学习</span>
          </li>
          <li>
            <p>{{$pub.formatSeconds(path.study.total_time)}}</p>
            <span>累计学习</span>
          </li>
          <li>
            <p>{{$pub.formatSeconds(path.study.live_time)}}</p>
            <span>直播课学习时长</span>
          </li>
          <li>
            <p>{{path.study.live_study_percent}}</p>
            <span>直播出勤率</span>
          </li>
          <li>
            <p>{{$pub.formatSeconds(path.study.play_time)}}</p>
            <span>录播课观看时长</span>
          </li>
        </ul>
      </div>
      <div class="bottom">
        <div class="question">
          <div class="path_title">
            <img src="../../images/member_new/path_question@2x.png">
            <span>问答统计</span>
          </div>
          <ul class="time_content">
            <li>
              <p>{{path.ask.ask_count}}</p>
              <span>提问数量</span>
            </li>
            <li>
              <p>{{path.ask.collected_count}}</p>
              <span>被收藏数量</span>
            </li>
            <li>
              <p>{{path.ask.praise_count}}</p>
              <span>被点赞量</span>
            </li>
            <li>
              <p>{{path.ask.collect_count}}</p>
              <span>收藏问答量</span>
            </li>
          </ul>
        </div>
        <div class="checkin">
          <div class="path_title">
            <img src="../../images/member_new/path_checkin.png">
            <span>打卡统计</span>
          </div>
          <ul class="time_content">
            <li>
              <p>{{path.exam.total_days}}</p>
              <span>打卡天数</span>
            </li>
            <li>
              <p>{{path.exam.question_count}}</p>
              <span>做题总数量</span>
            </li>
            <li>
              <p>{{path.exam.right_percent}}</p>
              <span>正确率</span>
            </li>
          </ul>
        </div>
      </div>
      <div class="practice">
        <div class="path_title">
          <img src="../../images/member_new/path_exercise@2x.png">
          <span>练习统计</span>
        </div>
        <ul class="time_content">
          <li>
            <p>{{path.questions.total_count}}</p>
            <span>做题总数量</span>
          </li>
          <li>
            <p>{{path.questions.error_count}}</p>
            <span>错题总数</span>
          </li>
          <li>
            <p>{{path.questions.right_percent}}</p>
            <span>正确率</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: "MemberPath",
  components: {

  },
  data () {
    return {
      path: {
        study: {},
        ask: {},
        exam: {},
        questions: {}
      },
      show: false
    };
  },
  async created () {
    this.goData();
  },
  methods: {
    goData () {
      this.$http.get(`/api/app/study/statistics`).then((res) => {
        this.path = res;
        this.show = true;
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.path {
  min-height: 1025px;
  padding: 20px 0 50px 0;
  .study {
    width: 807px;
    margin: 31px auto;
    height: 108px;
    padding: 21px;
    box-shadow: 0px 0px 8px 0px rgba(56, 56, 56, 0.1);
    border-radius: 10px;
  }
  .bottom {
    padding: 0 10px;
    display: flex;
    justify-content: space-around;
  }
  .question,
  .checkin,
  .practice
   {
    width: 364px;
    height: 108px;
    padding: 21px;
    box-shadow: 0px 0px 8px 0px rgba(56, 56, 56, 0.1);
    border-radius: 10px;
  }
  .practice {
    margin: 30px 0 0 30px;
  }
  .path_title {
    color: #333;
    font-size: 18px;
    img {
      width: 18px;
      height: 17px;
      vertical-align: middle;
      margin: 0 11px;
    }
  }
  .checkin img {
    width: 20px;
    height: 19px;
    margin-bottom: 3px;
  }
  .time_content {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 20px;
    li {
      text-align: center;
      color: #222222;
      font-size: 20px;
      p {
        font-weight: 500;
      }
      span {
        color: #999999;
        font-size: 14px;
      }
    }
  }
}
</style>
